@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/bebas-neue.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 300;
  src: url(fonts/sora-300.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/sora-400.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  src: url(fonts/sora-600.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
#navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
}
.logo {
  display: flex;
  color: #fff;
  align-items: center;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 0.2em;
}

.logo img {
  margin-right: 1.5rem;
}

#navbar-generated-avatars {
  background-color: #d5d8ff;
  height: 36px;
  border-radius: 48px;
  color: #150245;
  font-size: 16px;
  font-weight: 700;
  padding: 0px 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Bebas Neue';
  font-weight: 400;
  color: #fff;
  line-height: 0.75;
}

p {
  color: #fff;
  line-height: 1.3;
  font-size: 1rem;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  color: #fdeea2;
}

h1 {
  font-size: 12rem;
}

h2 {
  font-size: 2.5rem;
  line-height: 0.875;
}

@media (max-width: 767px) {
  p {
    font-size: 0.875rem;
  }
  h1 {
    font-size: 6rem;
  }
}

@media (max-width: 640px) {
  .logo {
    font-size: 0.75rem;
  }
  .logo img {
    width: 100px;
    height: 24px;
    margin-right: 1rem;
  }
}