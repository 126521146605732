.chose-your-image-contaienr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 5%;
  min-height: 89vh;
}
.chose-your-image-contaienr > h1 {
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  color: white;
  margin-bottom: 15px;
}
.chose-your-image-contaienr > h3 {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: white;
  margin-top: 15px;
}
.chose-your-image-contaienr > label {
  width: 156px;
  border-radius: 48px;
  background-color: #fee26f;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #150245;
  margin-top: 20px;
  cursor: pointer;
  padding: 15px 0px;
}
.chose-your-image-contaienr > div {
  background-image: linear-gradient(to right, #150245, #d5d8ff);
  width: 48%;
  height: 420px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.chose-your-image-list-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.chose-your-image-list-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.chose-your-image-list-1 > button {
  border: 2px solid;
  border-radius: 100px;
  width: 190px;
  height: 190px;
  margin: 0px 8px;
}
.chose-your-image-list-1 > button img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}
.chose-your-image-list-2 > button {
  width: 130px;
  height: 130px;
  border: 2px solid;
  border-radius: 100px;
  margin: 0px 5px;
}
.chose-your-image-list-2 > button > img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

@media only screen and (max-width: 1400px) {
  .chose-your-image-contaienr > h1 {
    font-size: 50px;
  }
  .chose-your-image-contaienr > h3 {
    font-size: 30px;
  }
  .chose-your-image-contaienr > button {
    width: 156px;
    height: 52px;
  }
  .chose-your-image-contaienr > div {
    width: 48%;
    height: 400px;
  }
  .chose-your-image-list-1 > button {
    width: 170px;
    height: 170px;
  }
  .chose-your-image-list-2 > button {
    width: 110px;
    height: 110px;
  }
}
@media only screen and (max-width: 1200px) {
  .chose-your-image-contaienr > div {
    width: 60%;
  }
}
@media only screen and (max-width: 1100px) {
  .chose-your-image-contaienr > h1 {
    font-size: 45px;
    margin-bottom: 10px;
  }
  .chose-your-image-contaienr > h3 {
    font-size: 25px;
  }
  .chose-your-image-contaienr > div {
    height: 320px;
  }
  .chose-your-image-list-1 > button {
    width: 150px;
    height: 150px;
  }
  .chose-your-image-list-2 > button {
    width: 100px;
    height: 100px;
  }
}
@media only screen and (max-width: 900px) {
  .chose-your-image-contaienr > div {
    width: 80%;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 700px) {
  .chose-your-image-contaienr > div {
    width: 90%;
  }
  .chose-your-image-contaienr > h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 600px) {
  .chose-your-image-list-1 > button {
    width: 100px;
    height: 100px;
  }
  .chose-your-image-list-2 > button {
    width: 60px;
    height: 60px;
  }
  .chose-your-image-contaienr > div {
    height: 300px;
  }
  .chose-your-image-contaienr > h1 {
    font-size: 25px;
  }
  .chose-your-image-contaienr > h3 {
    font-size: 20px;
  }
  .chose-your-image-contaienr > button {
    height: 40px;
  }
}
@media only screen and (max-width: 350px) {
  .chose-your-image-list-1 > button {
    width: 70px;
    height: 70px;
  }
  .chose-your-image-list-2 > button {
    width: 40px;
    height: 40px;
  }
  .chose-your-image-contaienr > div {
    height: 200px;
  }
  .chose-your-image-contaienr > h1 {
    font-size: 15px;
  }
  .chose-your-image-contaienr > h3 {
    font-size: 13px;
  }
}
