.layout-navbar {
  width: 100%;
  padding-top: 2rem;
  position: absolute;
  top: 0;
  left: 0;
}

.layout-data {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 3rem;
}