.generated-imgs {
  display: flex;
}

.generated-imgs-container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: 100%;
  min-height: 100vh;
  max-width: 64rem;
  justify-content: center;
}

@media (max-width: 767px) {
  .generated-imgs {
    flex-wrap: wrap;
  }
  .generated-img {
    margin: 1rem;
  }
  .generated-imgs-container {
    margin-top: 5rem;
  }
}

.generate-imgs-wrap h2 {
  margin: 0 auto 2.5rem auto;
  text-align: center;
}

.generated-img {
  box-shadow: 8px 15px 20px rgba(22, 2, 69, 0.7);
  margin: 0 1rem;
  background: #f9f8ff;
  text-decoration: none;
}

.generated-img > img {
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .generated-img {
    margin: 1rem;
    width: calc(50% - 2rem);
  }
}

@media (max-width: 640px) {
  .generated-img {
    width: calc(100% - 2rem);
  }
}

.download {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  text-align: center;
  text-decoration: none;
  background: #7769ff;
  font-size: 0.875rem;
  border-radius: 3rem;
  color: #fff;
  padding: 0.25rem 1rem;
  transition: all 0.3s ease-in-out;
}

.download img {
  margin-right: 0.5rem;
}

.download:hover {
  background: #160245;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (max-width: 767px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer .btn.btn-white {
    margin-bottom: 1rem;
  }
}

.scanning-message {
  border-radius: 5rem;
  background: #fff;
  border: 1px solid #7769ff;
  opacity: 0.91;
  padding: 1.75rem 5rem 1.5rem 5rem;
  margin: 0 auto 5rem auto;
  max-width: 42rem;
}

.scanning-message h2,
.scanning-message p {
  color: black;
}

.scanning-message h2 {
  margin-bottom: 0.5rem;
}

.gender-select-wrap {
  margin-top: 1rem;
}

.gender-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  appearance: none;
  cursor: pointer;
}

.gender-select:focus {
  outline: none;
}