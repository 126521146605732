.home-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
  min-height: 100vh;
  max-width: 64rem;
}

.home-left {
  max-width: 28rem;
}

.home-right {
  position: relative;
  z-index: 5;
  padding: 2.5rem 2rem 2.5rem 2rem;
}

.home-right-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  transform: translateY(-1.75rem);
}

.terms-wrap {
  margin-top: 1rem;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding: 0 2rem 2rem 2rem;
}

.terms-wrap p {
  font-size: 12px;
}

.terms-wrap p a {
  color: #fff;
}

.terms-wrap input {
  transform: scale(1.5);
  margin-right: 1rem;
  margin-top: 0.5rem;
}

@media (max-width: 767px) {
  .home-container {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 3.5rem;
    min-height: calc(100vh - 3.5rem);
  }
  .home-left {
    width: 100%;
    max-width: none;
  }
  .home-right {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    max-width: 28rem;
  }
}

.home-right:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#7769ff 0%, rgba(119, 105, 255, 0.6) 100%);
  opacity: 0.9;
  clip-path: polygon(8% 0, 100% 0, 100% 92%, 92% 100%, 0 100%, 0 8%);
  z-index: -1;
}

a,
button {
    transition: all 0.5s ease-in-out;
}

.btn {
  font-size: 1rem;
  line-height: 1.3;
  color: #160245;
  text-align: center;
  font-weight: bold;
  border: 0;
  padding: 1rem 3rem;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.btn-primary {
  background: none;
  color: #160245;
  z-index: 5;
}
.btn-primary:hover {
    color: #160245;
    background: none;
}
.btn-primary:after {
    content: "";
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    height: calc(100% - 0.5rem);
    width: calc(100% - 0.5rem);
    filter: drop-shadow(0px 8px 6px rgba(0, 0, 0, 0.11));
    background: #FFE67A;
    transform: skew(-30deg);
    z-index: -1;
    transition: all 0.5s ease-in-out;
}
.btn-primary:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: skew(-30deg);
    z-index: -1;
    border: 0.5px solid #f9f8ff;
    opacity: 0.25;
    transition: all 0.5s ease-in-out;
}
.btn-primary:hover:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.btn-white {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.btn-white img {
  margin-right: 1rem;
}
.btn-white:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: skew(-30deg);
  border: 2px solid #f9f8ff;
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
}
.btn-white:hover:before {
  background: #160245;
  border-color: #160245;
  opacity: 1;
}

.home-right-inner {
  display: flex;
}

.home-right-inner p {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.home-right > p {
  text-align: center;
  margin-top: 0.25rem;
}

.home-before {
  margin-right: 0.75rem;
}
.home-after {
  margin-left: 0.75rem;
}

.home-left h1 {
  transform: translateX(-0.3rem);
  margin-bottom: 2rem;
}

.home-left .eyebrow {
  text-transform: uppercase;
  margin-bottom: 1rem;
  letter-spacing: 0.2em;
  font-weight: bold;
}

.home-left .intro {
  font-size: 1.5rem;
}

@media (max-width: 767px) {
  .home-left .intro {
    font-size: 1.25rem;
  }
}