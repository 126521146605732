.upload-guide-container {
  display: flex;
  padding: 0 1rem;
  margin: 0 auto;
  width: 100%;
  max-width: 75rem;
  justify-content: space-between;
}

.upload-guide-left {
  flex-shrink: 0;
  padding-right: 3rem;
}

.upload-guide-right {
  max-width: 45rem;
}

.upload-guide-right-header {
  display: flex;
  margin-bottom: 3rem;
}

.upload-guide-right-header h2 {
  margin-right: 3rem;
}

.upload-guide-right-header p {
  font-size: 1.25rem;
}

.upload-guide-images {
  display: flex;
}

.uploaded-img-wrap {
  max-width: 24rem;
}

.uploaded-img {
  border-radius: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  height: auto;
  max-width: 20rem;
}

.reupload-button {
  color: #fff;
  font-weight: bold;
  display: flex;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.reupload-button:hover {
  color: #fdeea2;
}

.reupload-button img {
  margin-right: 0.5rem;
}

.uploaded-img-btns {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-guide-img-wrap {
  margin-left: 1rem;
}

.upload-guide-img-wrap:first-child {
  margin-left: 0;
}

.upload-guide-img {
  display: block;
  margin-bottom: 1rem;
  border-radius: 1rem;
}

.upload-guide-content p {
  margin-bottom: 0.75rem;
}

@media (max-width: 991px) {
  .upload-guide-container {
    display: block;
  }
  .upload-guide-left {
    padding: 0;
    margin-top: 5rem;
    margin-bottom: 3.5rem;
  }
  .uploaded-img-wrap {
    max-width: none;
  }
  .uploaded-img {
    margin-left: auto;
    margin-right: auto;
  }
  .upload-guide-right {
    margin: 0 auto;
  }
}

@media (max-width: 640px) {
  .upload-guide-right-header {
    display: block;
  }
  .upload-guide-right-header h2 {
    margin-bottom: 1rem;
  }
  .upload-guide-images {
    flex-wrap: wrap;
  }
  .upload-guide-img-wrap {
    width: 50%;
    margin: 0 0 2rem 0;
  }
  .upload-guide-img {
    width: calc(100% - 1rem);
  }
}